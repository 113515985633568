import { ref, onMounted, reactive, defineComponent } from 'vue'
import SmallCardListWithIcon from '@/views/components/list/HjpSmallCardListWithIcon.vue'
import ClassForm from '@/views/pages/master-setting/class/Form.vue'
import {
  get, map
} from 'lodash'

export default defineComponent({
  components: {
    SmallCardListWithIcon,
    ClassForm
  },
  setup() {
    const tabelParams = reactive({
      endpoint: '/management/v1/MsClass',
      headerTitle: 'Master Kelas',
      buttonTitle: 'Tambah Kelas',
      confirmDialogDelete: {
        title: 'Hapus Kelas?',
        subTitle: 'Pastikan Kelas yang Anda tambahkan sudah sesuai dan tidak ada perubahan ditengah-tengah.',
        btnSubmitTitle: 'Hapus kelas',
      }
    })
    // const slotBank = 'MsBank.Name'
    // const slotChannelBank = 'MsChannelBanks.OaAccount'
    // const getBankChannelId = (data: any) => {
    //   console.log('data', data)
    // }
    return {
      tabelParams,
      get,
      map
    }
  }
})
