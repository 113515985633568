import { defineComponent, reactive, ref, getCurrentInstance } from 'vue'
import { IRouteResponse } from '@/utils/interfaces/iRoute'
import apiUseCase from '@/usecase/apiUseCase'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
// Components
import HjpDataList from '@/views/components/list/HjpDataList.vue'
import HjpCofirmModal from '@/views/components/modal/HjpConfirmationModal.vue'
import Card from 'primevue/card'
import InputSwitch from 'primevue/inputswitch'
import OverlayPanel from 'primevue/overlaypanel'
import ListActions from '@/views/components/list/ListActions.vue'
import IResponse from '@/utils/interfaces/iResponse'

const endpoint = 'management/v1/MsRoute'

const RouteTable = defineComponent({
  name: 'RouteTable',
  components: {
    HjpDataList,
    Card,
    InputSwitch,
    OverlayPanel,
    ListActions,
    HjpCofirmModal
  },
  setup() {
    const app = getCurrentInstance()
    const {
      $toast
    } = app!.appContext.config.globalProperties
    const router = useRouter()
    const store = useStore()
    const params = reactive({
      endpoint: '/management/v1/MsRoute',
      isOpenNewPage: true,
      baseRoute: 'master-route',
      buttonTitle: 'Tambah Rute',
      headerTitle: 'Master Rute'
    })
    const dataRow = ref(null) as any
    const op = ref()
    const modalConfirmation = reactive({
      show: false,
      title: 'Hapus Rute?',
      subTitle: 'Menghapus Rute akan berdampak kepada kondektur dan trayek bus yang sudah diassign, Tetap hapus rute?',
      btnSubmitTitle: 'Hapus Rute',
    })
    const showDropDown = (evt: any, item: IRouteResponse) => {
      dataRow.value = item
      op.value.toggle(evt)
    }
    const onDelete = () => {
      modalConfirmation.show = true
      op.value.hide()
    }
    const onEdit = (id: string) => {
      // console.log('onEdit')
      router.push({
        name: 'master-route-form',
        params: {
          id
        }
      })
    }
    const onChangeActivate = async (item: IRouteResponse) => {
      store.dispatch('showLoading')
      try {
        const response = await apiUseCase.patch(endpoint, item.Id, {
          IsActive: item.IsActive
        })
        if (!response.error) {
          let msgActiv = 'diaktifkan'
          if (!item.IsActive) {
            msgActiv = 'dinonaktifkan'
          }
          const message = `Rute ${item.Code} berhasil ${msgActiv}`
          $toast.add({
            severity: 'success',
            detail: message,
            group: 'bc',
            closable: false,
            life: 3000
          })
        } else {
          $toast.add({
            severity: 'error',
            detail: response.message,
            group: 'bc',
            closable: false,
            life: 3000
          })
          item.IsActive = !item.IsActive
        }
        store.dispatch('hideLoading')
        // store.dispatch('setReloadDataList')
      } catch (err) {
        store.dispatch('hideLoading')
      }
    }
    const hideConfirmModal = () => {
      modalConfirmation.show = false
    }
    const doSubmit = () => {
      store.dispatch('showLoading')
      hideConfirmModal()
      apiUseCase.delete(endpoint, dataRow.value.Id)
        .then(({ error, message }: IResponse) => {
          if (!error) {
            $toast.add({
              severity: 'success',
              detail: 'Data berhasil dihapus',
              group: 'bc',
              closable: false,
              life: 3000
            })
            store.dispatch('setReloadDataList')
          } else {
            $toast.add({
              severity: 'error',
              detail: message,
              group: 'bc',
              closable: false,
              life: 3000
            })
            store.dispatch('setReloadDataList')
          }
          store.dispatch('hideLoading')
        })
        .catch(({ response }) => {
          console.log('response err =>', response)
          store.dispatch('hideLoading')
        })
    }
    return {
      params,
      showDropDown,
      op,
      onDelete,
      onEdit,
      dataRow,
      onChangeActivate,
      modalConfirmation,
      hideConfirmModal,
      doSubmit
    }
  }
})

export default RouteTable
