import store from '@/store'

const setup = (api: any) => {
  api.interceptors.response.use(async (response: any) => response, async (err: any) => {
    if (err.response.status === 401) {
      store.dispatch('logoutAccount')
    }
    return Promise.reject(err)
  })
}

export default setup
