import { onMounted, reactive, ref, toRefs, getCurrentInstance } from 'vue'
import type { PropType, Ref } from 'vue'
import HeaderCustom from '@/views/components/header/HeaderHjpTable.vue'
import Card from 'primevue/card'
import OverlayPanel from 'primevue/overlaypanel'
import {
  cityTerminalUseCase
} from '@/usecase'
import _ from 'lodash'
import '@/views/pages/master-setting/city-terminal/city-terminal.scss'
import IResponse from '@/utils/interfaces/iResponse'
import {
  ICityTerminalResponse,
  TypeTerminals
} from '@/utils/interfaces/iCityTerminal'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import $ from 'jquery'
import ListActions from '@/views/components/list/ListActions.vue'
import HjpConfirmationModal from '@/views/components/modal/HjpConfirmationModal.vue'
import Paginator from '@/views/components/paginator/Paginator.vue'
import ModalTerminal from '@/views/pages/master-setting/city-terminal/components/ModalTerminal.vue'
import EmptyState from '@/views/components/list/EmptyState.vue'

export default {
  name: 'CityTerminal',
  components: {
    HeaderCustom,
    Card,
    ModalTerminal,
    OverlayPanel,
    ListActions,
    HjpConfirmationModal,
    Paginator,
    EmptyState
  },
  setup(props: any, { emit }: any) {
    const app = getCurrentInstance()
    const {
      $toast
    } = app!.appContext.config.globalProperties
    const router = useRouter()
    const store = useStore()
    const title = ref('Master Kota & Terminal')
    const titleButton = ref('Tambah Kota / Terminal')
    const op = ref()
    const dataSource = ref([]) as Ref<Array<ICityTerminalResponse>>
    const search: Ref<string> = ref('')
    const isLoading: Ref<boolean> = ref(true)
    const showModal = ref<boolean>(false)
    const dataRow = ref<ICityTerminalResponse>() as any
    const paginate = reactive({
      pageSize: 10,
      pageNumber: 1,
      totalRecords: 0,
      isActive: true
    })
    const attrModalConfirmation = reactive({
      show: false,
      title: 'Hapus Kota & Terminal?',
      subTitle: '',
      btnSubmitTitle: 'Hapus Kota & Terminal',
    })
    const getData = async () => {
      store.dispatch('showLoading')
      try {
        const response = await cityTerminalUseCase.getAll({
          search: search.value,
          ...paginate
        }, ['name', 'code', 'kota'])
        const {
          result,
          pagesize,
          count
        } = response
        const manageData: Array<ICityTerminalResponse> = _.chain(result).groupBy('IdCity').map((val, key) => ({
          IdCity: key,
          CityName: val[0].CityName,
          Terminals: val
        })).value()
        dataSource.value = manageData
        paginate.pageSize = pagesize
        paginate.totalRecords = count
        isLoading.value = false
        store.dispatch('hideLoading')
      } catch (error) {
        console.log('error: ', error)
        store.dispatch('hideLoading')
      }
    }

    const getSearchData = async () => {
      store.dispatch('showLoading')
      try {
        const response = await cityTerminalUseCase.searchStation({
          search: search.value,
          ...paginate
        })
        const {
          result,
          pagesize,
          count
        } = response
        const manageData: Array<ICityTerminalResponse> = _.chain(result).groupBy('IdCity').map((val, key) => ({
          IdCity: key,
          CityName: val[0].CityName,
          Terminals: val
        })).value()
        dataSource.value = manageData
        paginate.pageSize = pagesize
        paginate.totalRecords = count
        isLoading.value = false
        store.dispatch('hideLoading')
      } catch (error) {
        console.log('error: ', error)
        store.dispatch('hideLoading')
      }
    }
    const onChangePage = (event: any) => {
      paginate.pageNumber = event.page + 1
      if (search.value === '') {
        getData()
      } else {
        getSearchData()
      }
    }
    const redirectAdd = () => {
      router.push({
        name: 'master-city-terminal-form'
      })
    }
    const redirectEdit = () => {
      console.log('ccc')
    }

    const showDropDown = (evt: any, valItem: any) => {
      dataRow.value = valItem
      op.value.toggle(evt)
    }

    const onSearch = (val: string) => {
      search.value = val
      paginate.pageNumber = 1
      if (search.value === '') {
        getData()
      } else {
        getSearchData()
      }
    }
    const onHoverEnter = (idx: number) => {
      $(`.icon-action:eq(${idx})`).removeClass('invisible')
    }
    const onHoverLeave = (idx: number) => {
      $(`.icon-action:eq(${idx})`).addClass('invisible')
    }
    const addNew = (item: any) => {
      showModal.value = true
      dataRow.value = item
    }
    const hideDialog = () => {
      showModal.value = false
    }
    const onDelete = () => {
      attrModalConfirmation.show = true
      attrModalConfirmation.subTitle = `Menghapus Kota ${dataRow.value.CityName} & ${dataRow.value.Terminals.length} Terminal akan berdampak kepada kondektur dan trayek bus yang sudah diassign, Tetap hapus Kota & Terminal?`
    }
    const onEdit = () => {
      router.push({
        name: 'master-city-terminal-form',
        params: {
          id: dataRow.value.CityName
        }
      })
    }
    const onSubmit = async (val: any) => {
      try {
        const arrPayload = []
        const payload = {
          ...val,
          Latitude: val.Latlng.lat,
          Longitude: val.Latlng.lng,
          IdCity: dataRow.value.IdCity,
          CityName: dataRow.value.CityName
        }
        arrPayload.push(payload)
        const response = await cityTerminalUseCase.submitData(arrPayload)
        if (!response.error) {
          setTimeout(() => {
            $toast.add({
              severity: 'success',
              detail: 'Data berhasil ditambahkan',
              group: 'bc',
              life: 3000
            })
            getData()
          }, 500)
        }
      } catch (err) {
        console.log('err submit', err)
      }
    }
    const doDelete = async () => {
      store.dispatch('showLoading')
      try {
        const payloads = await dataRow.value.Terminals
        cityTerminalUseCase.deleteData(payloads[0].IdCity).then(({ error, message }: IResponse) => {
          if (!error) {
            $toast.add({
              severity: 'success',
              detail: `Terminal ${payloads[0].CityName} berhasil dihapus`,
              group: 'bc',
              life: 3000
            })
            setTimeout(() => {
              getData()
            }, 500)
          } else {
            $toast.add({
              severity: 'error',
              detail: message,
              group: 'bc',
              life: 3000
            })
            store.dispatch('hideLoading')
          }
          attrModalConfirmation.show = false
        })
      } catch (error) {
        console.log('error delete', error)
      }
    }
    const hideConfirmModal = () => {
      attrModalConfirmation.show = false
    }
    onMounted(() => {
      getData()
    })
    return {
      dataSource,
      isLoading,
      paginate,
      title,
      titleButton,
      redirectAdd,
      showDropDown,
      op,
      redirectEdit,
      onSearch,
      onHoverEnter,
      onHoverLeave,
      addNew,
      showModal,
      hideDialog,
      onDelete,
      onEdit,
      onSubmit,
      attrModalConfirmation,
      doDelete,
      hideConfirmModal,
      onChangePage
    }
  },
}
