import { ref, onMounted, reactive, defineComponent } from 'vue'
import HjpDataList from '@/views/components/list/HjpDataList.vue'
import PassengerForm from '@/views/pages/master-setting/passenger-type/Form.vue'
import OverlayPanel from 'primevue/overlaypanel'
import ListActions from '@/views/components/list/ListActions.vue'
import { IPassengerTypeResponse } from '@/utils/interfaces/iPassengerType'
import { useStore } from 'vuex'

export default defineComponent({
  components: {
    PassengerForm,
    HjpDataList,
    OverlayPanel,
    ListActions
  },
  setup() {
    const dataRow = ref<IPassengerTypeResponse|null>(null)
    const op = ref()
    const store = useStore()
    const tabelParams = reactive({
      endpoint: '/management/v1/MsPassenger',
      headerTitle: 'Master Jenis Penumpang',
      buttonTitle: 'Tambah Jenis Penumpang',
      confirmDialogDelete: {
        title: 'Hapus Jenis Penumpang?',
        subTitle: 'Apakah Anda yakin ingin mengubah atau menghapusnya?',
        btnSubmitTitle: 'Hapus Jenis Penumpang',
      }
    })
    const showDropDown = (evt: any, item: IPassengerTypeResponse) => {
      dataRow.value = item
      op.value.toggle(evt)
    }
    const onDelete = () => {
      store.dispatch('storeRowDataListDl', dataRow.value)
      store.dispatch('setShowModalConfirmationDl', true)
    }
    const onEdit = () => {
      store.dispatch('storeRowDataListDl', dataRow.value)
      store.dispatch('setShowModalFormDl', true)
    }
    return {
      tabelParams,
      op,
      onEdit,
      onDelete,
      showDropDown
    }
  }
})
