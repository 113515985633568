import { defineComponent, ref, watch, onMounted, computed, getCurrentInstance, reactive } from 'vue'
import type { Ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { cityTerminalUseCase } from '@/usecase'
import {
  TypeTerminals,
  IMsCityResponse
} from '@/utils/interfaces/iCityTerminal'
import IResponse from '@/utils/interfaces/iResponse'
import vSelect from 'vue-select'
import Card from 'primevue/card'
import Button from 'primevue/button'
import { useSnackbar } from 'vue3-snackbar'
import HjpConfirmationModal from '@/views/components/modal/HjpConfirmationModal.vue'
import ModalTerminal from '@/views/pages/master-setting/city-terminal/components/ModalTerminal.vue'
import SelectFetch from '@/views/components/form/SelectAsync.vue'
import { useStore } from 'vuex'

const backRouteName = 'master-city-terminal'
const moduleStore = 'cityTerminal'

export default defineComponent({
  name: 'CityTerminalForm',
  components: {
    Card,
    vSelect,
    ModalTerminal,
    Button,
    HjpConfirmationModal,
    SelectFetch
  },
  setup() {
    const app = getCurrentInstance()
    const {
      $toast
    } = app!.appContext.config.globalProperties
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const isEdit = ref<boolean>(route.params?.id ? true : false)
    const selectCity = ref(null) as any
    const showModal: Ref<boolean> = ref(false)
    const itemsTerminal = computed<TypeTerminals[]>({
      get: () => store.state[moduleStore].itemsTerminal,
      set: (val) => {
        store.dispatch(`${moduleStore}/storeItemTerminal`, val)
      }
    })
    const snackbar = useSnackbar()
    const modalConfirmation = reactive({
      show: false,
      imgContent: require('@/assets/img/modal-confirmation/city&terminal-save.svg'),
      title: '',
      subTitle: '',
      btnSubmitTitle: '',
    })

    const hideDialog = () => {
      showModal.value = false
    }

    const showingModal = () => {
      showModal.value = true
    }

    const hideConfirmModal = () => {
      modalConfirmation.show = false
    }

    const fetchTerminal = async (code: string) => {
      try {
        const { result } = await cityTerminalUseCase.getAll({
          search: code,
        }, ['kota'])
        itemsTerminal.value = result
      } catch (error) {
        console.log('error', error)
      }
    }

    const submitModal = (val: any) => {
      const tempItemsTerminal = itemsTerminal.value
      tempItemsTerminal.push({
        ...val,
        Latitude: val.Latlng.lat,
        Longitude: val.Latlng.lng,
        IsDeleted: false
      })
      itemsTerminal.value = tempItemsTerminal
    }

    const onBack = () => {
      router.push({
        name: backRouteName
      })
    }

    const doSubmit = () => {
      // console.log('itemsTerminal', itemsTerminal.value)
      try {
        if (itemsTerminal.value.length > 0) {
          modalConfirmation.show = false
          if (!route.params.id) {
            itemsTerminal.value = itemsTerminal.value.filter((x: any) => x.IsDeleted === false)
          }
          const payload = itemsTerminal.value.map((val) => ({
            ...val,
            IdCity: selectCity.value.value,
            CityName: selectCity.value.label
          }))
          // console.log('payload', payload)
          cityTerminalUseCase.submitData(payload).then((res: IResponse) => {
            $toast.add({
              severity: 'success',
              summary: `Berhasil ${route.params.id ? 'merubah' : 'menambahkan'} Kota & Terminal`,
              detail: `Kota dan terminal sudah ${route.params.id ? 'dirubah' : 'ditambahkan'} dan dapat digunakan pada fitur lain.`,
              group: 'bc',
              life: 3000
            })
            router.push({
              name: 'master-city-terminal'
            })
          })
        }
      } catch (error) {
        console.log('error dosubmit:', error)
      }
    }

    const submit = () => {
      try {
        if (route.params.id) {
          modalConfirmation.title = 'Edit Kota & Terminal?'
          modalConfirmation.subTitle = 'Pastikan data kota dan terminal sudah benar dan sesuai dengan yang Anda inginkan.'
          modalConfirmation.btnSubmitTitle = 'Simpan Perubahan'
        } else {
          modalConfirmation.title = 'Simpan Kota & Terminal?'
          modalConfirmation.subTitle = 'Pastikan data kota dan terminal sudah benar dan sesuai dengan yang Anda inginkan.'
          modalConfirmation.btnSubmitTitle = 'Simpan Kota & Terminal'
        }
        modalConfirmation.show = true
      } catch (error) {
        console.log('error submit:', error)
      }
    }

    const fetchDetailTerminal = () => {
      cityTerminalUseCase.getAll({ search: route.params.id }, ['kota']).then((response) => {
        if (response.result.length > 0) {
          selectCity.value = {
            label: response.result[0].CityName,
            value: response.result[0].IdCity,
          }
          itemsTerminal.value = response.result
        }
      })
    }

    const deleteItemTerminal = (item: TypeTerminals) => {
      // console.log('delte item', item)
      if (item?.Id) {
        cityTerminalUseCase.checkStationUsed(item.Id).then(({ error, message }: IResponse) => {
          if (!error) {
            item.IsDeleted = true
            snackbar.add({
              title: 'Berhasil menghapus terminal..',
              background: '#252F40',
              dismissible: false,
              duration: 1000
            })
          } else {
            snackbar.add({
              title: message,
              background: '#F40023',
              dismissible: false,
              duration: 1000
            })
          }
        })
      } else {
        item.IsDeleted = true
        snackbar.add({
          title: 'Berhasil menghapus terminal..',
          background: '#252F40',
          dismissible: false,
          duration: 1000
        })
      }
    }

    watch(() => selectCity.value, (val: any) => {
      fetchTerminal(val.label)
    })

    onMounted(() => {
      let editOrAdd = 'Tambah Kota & Terminal'
      if (route.params.id) {
        fetchDetailTerminal()
        editOrAdd = 'Edit Kota & Terminal'
      }
      store.dispatch('ui/setBreadcrumbs', [
        {
          title: 'Master Kota & Terminal',
          routeName: backRouteName
        },
        {
          title: editOrAdd,
          routeName: `${backRouteName}-form`
        }
      ])
    })

    return {
      selectCity,
      showModal,
      hideDialog,
      showingModal,
      submitModal,
      itemsTerminal,
      onBack,
      submit,
      isEdit,
      deleteItemTerminal,
      modalConfirmation,
      doSubmit,
      hideConfirmModal
    }
  }
})
