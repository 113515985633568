import axios from 'axios'
import environment from '@/configs/environtment'

const API_URL = environment.getOtpTokenBaseUrl() ?? environment.getOtpBaseUrl()
const API_URLX = environment.getOtpBaseUrl()
console.log('apiurl', API_URL)
console.log('apiurlx', API_URLX)
// const API_URL = 'https://stgapi.hjex.co.id'
const api = axios.create({
  baseURL: API_URL,
  withCredentials: false,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
    Accept: '*/*',
    // 'Access-Control-Allow-Origin': true
    // 'Access-Control-Allow-Origin': '*'
    // 'Content-Type': 'application/x-www-form-urlencoded'
  },
  // httpsAgent
})

api.interceptors.request.use((config: any) => config, (error: any) => Promise.reject(error))

api.interceptors.response.use((response: any) => response, (error: any) => Promise.reject(error))

export default api
