import iconDependency from '@/dependencies/iconDependency'

const reportOnlyMenu = () => {
  const iconSvg = iconDependency()
  return [
    {
      name: 'BERANDA',
      items: [
        {
          icon: iconSvg.report,
          label: 'Laporan',
          route: 'reports',
          prohibitedRoles: []
        }
      ],
      prohibitedRoles: []
    },
  ]
}

export default reportOnlyMenu
