import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Toast = _resolveComponent("Toast")!
  const _component_vue3_snackbar = _resolveComponent("vue3-snackbar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_router_view),
    _createVNode(_component_Toast, {
      position: "bottom-right",
      class: "toast-og",
      group: "br"
    }),
    _createVNode(_component_Toast, {
      position: "bottom-center",
      class: "toast-og",
      group: "bc"
    }),
    _createVNode(_component_vue3_snackbar, {
      bottom: "",
      dense: "",
      groups: "",
      messageClass: "hjp-snackbar",
      info: "#252F40"
    })
  ], 64))
}