import { useRouter } from 'vue-router'
import DropdownAbsolute from '@/views/shared-components/DropdownAbsolute.vue'
import {
  useStore
} from 'vuex'
import { onMounted, defineComponent } from 'vue'
import InlineSvg from 'vue-inline-svg'

export default defineComponent({
  name: 'ProfileDropdown',
  components: {
    DropdownAbsolute,
    InlineSvg
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    // console.log('anyy', store.state.token)
    const logout = () => {
      // const response = await sendingLogOutAccount(store.state.token.idToken)
      // if (!response.error) {
      // window.location.href = `${environment.getHydraApi()}oauth2/sessions/logout?id_token_hint=${store.state.token.idToken}&post_logout_redirect_uri=${window.location.origin}`
      store.dispatch('logoutAccount').then(() => {
        (window as Window).location.href = '/'
      })
      // console.log('ini store', store.state.token.idToken)
      // console.log('lklkk', `${environment.getHydraApi()}oauth2/sessions/logout?id_token_hint=${store.state.token.idToken}`)
      // }
    }

    const toProfilAgen = () => {
      router.push({ name: 'profil-agen' })
    }

    const changePassword = () => {
      store.dispatch('setToogleChangePasswordDialog', true)
      console.log('state', store.state)
    }

    const reload = () => {
      window.location.reload()
    }

    onMounted(() => {
      // store.dispatch('fetchProfile')
    })

    return {
      logout,
      changePassword,
      reload,
      toProfilAgen
    }
  }
})
