
import {
  onMounted,
  watch
} from 'vue'
import {
  useStore
} from 'vuex'
import { useRoute } from 'vue-router'
import { useFavicon } from '@vueuse/core'

export default {
  setup() {
    const store = useStore()
    const route: any = useRoute()
    useFavicon('static/favicon-17x17.png', {
      baseUrl: '/',
      rel: 'icon',
    })
    // icon.value =

    onMounted(() => {
      store.dispatch('setWidth')
      store.dispatch('initResize')
      store.dispatch('initNetworkOff')
      store.dispatch('initNetworkOn')
    })
    watch(route, (val: any) => {
      store.dispatch('ui/setBreadcrumbs', [])
      if (val?.meta?.title) {
        document.title = `HJP - ${val.meta.title}`
      } else {
        document.title = 'HJP'
      }
    })
    return {}
  },
}
