import { ref, onMounted, reactive, defineComponent } from 'vue'
import SmallCardListWithIcon from '@/views/components/list/HjpSmallCardListWithIcon.vue'
import ConstraintTypeForm from '@/views/pages/master-setting/constraint-type/Form.vue'
import {
  get, map
} from 'lodash'
import { useStore } from 'vuex'

export default defineComponent({
  components: {
    SmallCardListWithIcon,
    ConstraintTypeForm
  },
  setup() {
    const store = useStore()
    const tabelParams = reactive({
      endpoint: '/management/v1/MsConstraint',
      headerTitle: 'Master Jenis Kendala',
      buttonTitle: 'Tambah Jenis Kendala',
      confirmDialogDelete: {
        title: 'Hapus Jenis Kendala?',
        subTitle: 'Pastikan Jenis Kendala yang Anda tambahkan sudah sesuai dan tidak ada perubahan ditengah-tengah.',
        btnSubmitTitle: 'Hapus Jenis Kendala',
      }
    })
    // const slotBank = 'MsBank.Name'
    // const slotChannelBank = 'MsChannelBanks.OaAccount'
    // const getBankChannelId = (data: any) => {
    //   console.log('data', data)
    // }
    onMounted(() => {
      console.log('store', store.state)
    })
    return {
      tabelParams,
      get,
      map
    }
  }
})
