import { defineComponent, onMounted, reactive, ref, computed, getCurrentInstance } from 'vue'
import { useStore } from 'vuex'
import * as Yup from 'yup'
import { useRoute, useRouter } from 'vue-router'
import { IRouteResponse } from '@/utils/interfaces/iRoute'

import { Form, Field, FieldArray, ErrorMessage } from 'vee-validate'
import InputText from 'primevue/inputtext'
import InputSwitch from 'primevue/inputswitch'
import Checkbox from 'primevue/checkbox'
import Button from 'primevue/button'
import Textarea from 'primevue/textarea'
import Card from 'primevue/card'
import draggable from 'vuedraggable'
import vSelect from 'vue-select'
import HjpConfirmationModal from '@/views/components/modal/HjpConfirmationModal.vue'
import SelectFetch from '@/views/components/form/SelectAsync.vue'
import apiUseCase from '@/usecase/apiUseCase'
import { cityTerminalUseCase } from '@/usecase'
import { TypeTerminals } from '@/utils/interfaces/iCityTerminal'
import { debounce } from 'lodash'
import returnUrlPrams from '@/utils/helpers/returnUrlPrams'
import rgxExp from '@/utils/helpers/regExp'
import { VueEternalLoading, LoadAction } from '@ts-pro/vue-eternal-loading'
import ProgressSpinner from 'primevue/progressspinner'

const backRouteName = 'master-route'
const endpoint = 'management/v1/MsRoute'

const RouteForm = defineComponent({
  name: 'RouteForm',
  components: {
    Form,
    Field,
    FieldArray,
    InputText,
    InputSwitch,
    Button,
    // Textarea,
    Card,
    draggable,
    vSelect,
    HjpConfirmationModal,
    ErrorMessage,
    SelectFetch,
    Checkbox
  },
  setup() {
    const airportRouteId = 1
    const akdpRouteId = 2

    const app = getCurrentInstance()
    const {
      $toast
    } = app!.appContext.config.globalProperties
    const title = ref('Tambah Rute')
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const isLoading = ref(true)
    const isHasPrice = ref(false)
    const isDraggable = ref(true)
    const list = ref([]) as any
    const modalConfirmation = reactive({
      show: false,
      imgContent: '',
      title: '',
      subTitle: '',
      btnSubmitTitle: 'Simpan Rute',
    })
    const tempLengthOfTerminalList = ref(0)
    const pageNumberTerminal = ref(1)
    const isInitialTerminal = ref(false)/** variable yang digunakan untuk mereset state menggunakan plugin vue-eternal-loading */
    const stopLoadTerminal = ref(false)
    const refForm = ref<InstanceType<typeof Form>>()

    const optionTerminals = ref([]) as any
    const initialValues = ref<Partial<IRouteResponse>>({
      Code: '',
      Name: '',
      Description: '',
      IsActive: true,
      IsAirport: false,
      Routes: []
    })

    const schema = Yup.object().shape({
      Code: Yup.string().required('Kode Rute harus diisi')
        .max(30, 'Kode Rute maksimal 30 karakter')
        .min(2, 'Kode Rute minimal 2 karakter')
        .matches(rgxExp.notPrefixSpace, 'Kode Rute tidak boleh hanya menggunakan spasi')
        .matches(rgxExp.notSpaceChar, 'Kode Rute tidak boleh menggunakan spasi')
        .test('unique-code', 'Kode Rute sudah digunakan', async (val) => {
          if (!val) return true
          try {
            const res = await apiUseCase.get(`${endpoint}${returnUrlPrams({
              search: val,
              filterField: ['code']
            })}`)
            const filterResult = res.result.filter((x: any) => x.Code === val)
            if (route.params.id) {
              return filterResult.length === 0 || val === initialValues.value.Code
            } return filterResult.length === 0
          } catch (error) {
            return false
          }
        }),
      Name: Yup.string().required('Nama Rute harus diisi')
        .max(50, 'Nama Rute maksimal 50 karakter')
        .matches(rgxExp.notPrefixSpace, 'Nama Rute tidak boleh hanya menggunakan spasi')
        .test('unique-name', 'Nama Rute sudah digunakan', async (val) => {
          if (!val) return true
          try {
            const res = await apiUseCase.get(`${endpoint}${returnUrlPrams({
              search: val,
              filterField: ['name']
            })}`)
            const filterResult = res.result.filter((x: any) => x.Name === val)
            if (route.params.id) {
              return filterResult.length === 0 || val === initialValues.value.Name
            } return filterResult.length === 0
          } catch (error) {
            return false
          }
        }),
      Routes: Yup.array().of(
        Yup.object().shape({
          value: Yup.string().required('Pilih terminal dahulu')
        })
        // eslint-disable-next-line
      ).test('unique', 'Tidak boleh menggunankan terminal yang sama', (value: any) => {
        return value ? value.length === new Set(value.map((d: any) => d.value))?.size : true
        // }).test({
        //   message: 'Terminal minimal menggunakan 2 terminal',
        //   test: (arr: any) => {
        //     console.log(arr.length)
        //     return arr.length < 2
        //   },
      }).min(2, 'Terminal minimal menggunakan 2 terminal')
    })

    const dataForm: any = ref(null)

    const onBack = () => {
      router.push({
        name: backRouteName
      })
    }

    const onSelect = (val: any) => {
      console.log('value', val)
    }

    const doSearch = async (search: string | null = null) => {
      await cityTerminalUseCase.getAll({
        search,
        pageNumber: pageNumberTerminal.value
      }, ['name'])
        .then((response) => {
          tempLengthOfTerminalList.value = response.result.length
          const optionTerminalsTemp = response.result.map((val: TypeTerminals) => ({
            ...val,
            label: `${val.CityName} - ${val.Name}`,
            value: val.Id,
          }))
          optionTerminalsTemp.forEach((x: any) => optionTerminals.value.push(x))
          console.log('optionTerminals.value', optionTerminals.value)
        })
    }

    const onSearchTerminal = (val: any) => {
      console.log('val', val)
      pageNumberTerminal.value = 1
      optionTerminals.value = []
      if (optionTerminals.value.length === 0) {
        doSearch(val)
      } else {
        onSearchTerminal(val)
      }
    }

    const onSubmit = (values: any) => {
      // console.log('values submit', values)
      store.dispatch('showLoading')
      dataForm.value = values
      store.dispatch('hideLoading')
      modalConfirmation.show = true
    }

    const hideModalConfirmation = () => {
      modalConfirmation.show = false
      dataForm.value = null
    }

    const doSubmitConfirm = async () => {
      // console.log('submit confirm', dataForm.value)
      modalConfirmation.show = false
      store.dispatch('showLoading')
      try {
        const payload = {
          Name: dataForm.value.Name,
          Code: dataForm.value.Code,
          Description: dataForm.value.Description,
          IsActive: dataForm.value.IsActive,
          MsRouteTypeId: dataForm.value.IsAirport ? airportRouteId : akdpRouteId,
          Detail: dataForm.value.Routes.map((ro: any, idx: number) => ({
            MsStationId: ro.value,
            Order: idx
          }))
        }
        const {
          error
        } = await apiUseCase.postOrPut(endpoint, route.params.id, payload)
        let msgHeader = 'Penambahan Rute Berhasil!'
        let msgDetail = 'Rute sudah bisa digunakan dan diassign pada kondektur / kontroller.'
        if (route.params.id) {
          msgHeader = 'Perubahan Rute Berhasil!'
          msgDetail = 'Perubahan rute berdampak kepada fitur lain, segera pastikan agar tidak miss.'
        }
        if (!error) {
          $toast.add({
            severity: 'success',
            summary: msgHeader,
            detail: msgDetail,
            group: 'bc',
            closable: false,
            life: 3000
          })
          router.push({
            name: backRouteName
          })
        }
        store.dispatch('hideLoading')
      } catch (error) {
        console.log('error routes', error)
        store.dispatch('hideLoading')
      }
    }

    const getDetailData = () => {
      store.dispatch('showLoading')
      apiUseCase.get(`${endpoint}/${route.params.id}`)
        .then(async ({ result }) => {
          const tempResult = await {
            ...result,
            IsAirport: result.MsRouteTypeId === 1,
            Routes: result.Terminal.map((ro: any) => ({
              ...ro,
              label: `${ro.CityName} - ${ro.Name}`,
              value: ro.TerminalId,
              // optionTerminals: []
            }))
          }
          isHasPrice.value = tempResult.HasPrice
          initialValues.value = tempResult
          isLoading.value = false
          list.value = tempResult.Routes
          // console.log('temp result', initialValues.value)
          store.dispatch('hideLoading')
        })
        .catch(() => {
          store.dispatch('hideLoading')
        })
    }

    const loadDataTerminal = async ({ loaded, noMore }: LoadAction) => {
      console.log('aaa', tempLengthOfTerminalList.value)
      if (tempLengthOfTerminalList.value >= 10 && !stopLoadTerminal.value) {
        pageNumberTerminal.value += 1
        doSearch().then((x: any) => {
          if (tempLengthOfTerminalList.value < 10) {
            console.log('<10', tempLengthOfTerminalList.value)
            stopLoadTerminal.value = true
            /** menggunakan fungsi unutuk mereset state data kosong type dri interface plugin vue-eternal-loading */
            noMore()
          } else {
            console.log('xxx')
            /** menggunakan fungsi unutuk load state type dri interface plugin vue-eternal-loading */
            loaded()
          }
        })
      } else {
        noMore()
      }
    }

    const checkMove = (evt: any, swap: any) => {
      // console.log('evt', evt)
      swap(evt.moved.oldIndex, evt.moved.newIndex)
      // console.log('logger ref', refForm.value?.getValues())
      // if (evt.draggedContext.index > evt.draggedContext.futureIndex) {
      //   swap(evt.draggedContext.futureIndex - 1, evt.draggedContext.index)
      // } else {
      //   swap(evt.draggedContext.futureIndex + 1, evt.draggedContext.index)
      // }
    }

    onMounted(() => {
      doSearch()
      if (route.params.id) {
        title.value = 'Edit Rute'
        modalConfirmation.imgContent = require('@/assets/img/modal-confirmation/route-update.svg')
        modalConfirmation.title = 'Rute Sudah digunakan, Lanjut ubah?'
        modalConfirmation.subTitle = 'Data yang Anda pilih sudah dipakai di fitur lain, Apakah Anda yakin ingin mengubah atau menghapusnya?'
        modalConfirmation.btnSubmitTitle = 'Simpan Perubahan'
        getDetailData()
      } else {
        modalConfirmation.imgContent = require('@/assets/img/modal-confirmation/route-save.svg')
        modalConfirmation.title = 'Simpan Rute?'
        modalConfirmation.subTitle = 'Rute yang telah disimpan dan telah memiliki harga tidak dapat diubah. Pastikan data dan urutan terminal sudah benar dan sesuai dengan yang Anda inginkan.'
        isLoading.value = false
      }
      console.log('addOrEdit', title.value)
      store.dispatch('ui/setBreadcrumbs', [
        {
          title: 'Master Rute',
          routeName: 'master-route'
        },
        {
          title: title.value,
          routeName: 'master-route-form'
        }
      ])
    })
    return {
      onSubmit,
      schema,
      initialValues,
      onBack,
      onSelect,
      modalConfirmation,
      hideModalConfirmation,
      doSubmitConfirm,
      isLoading,
      onSearchTerminal,
      optionTerminals,
      isHasPrice,
      loadDataTerminal,
      isInitialTerminal,
      title,
      isDraggable,
      checkMove,
      list,
      refForm
    }
  },
})

export default RouteForm
