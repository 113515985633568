// Components
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import InputSwitch from 'primevue/inputswitch'
import ProgressSpinner from 'primevue/progressspinner'
import HeaderCustom from '@/views/components/header/HeaderHjpTable.vue'
import Paginator from '@/views/components/paginator/Paginator.vue'
import ListActions from '@/views/components/list/ListActions.vue'
import HjpSideBar from '@/views/components/sidebar/HjpSideBar.vue'
import HjpCofirmModal from '@/views/components/modal/HjpConfirmationModal.vue'
import HjpDefaultModal from '@/views/components/modal/ModalDefault.vue'
import Card from 'primevue/card'
import OverlayPanel from 'primevue/overlaypanel'

import { onMounted, reactive, ref, toRefs, defineComponent, computed, PropType, getCurrentInstance, watch } from 'vue'
import apiUseCase from '@/usecase/apiUseCase'
import $ from 'jquery'
import {
  useStore
} from 'vuex'
import '@/views/components/table/hjp-data-table.scss'
import {
  IConfirmDialogDelete,
  IObjectOfColumns
} from '@/utils/interfaces/iHjpDataTable'
import {
  get
} from 'lodash'
import IResponse from '@/utils/interfaces/iResponse'
import EmptyState from '@/views/components/list/EmptyState.vue'

const moduleStore = 'hjpTable'

export default defineComponent({
  components: {
    DataTable,
    Column,
    HeaderCustom,
    Paginator,
    ListActions,
    HjpSideBar,
    InputSwitch,
    HjpCofirmModal,
    ProgressSpinner,
    Card,
    OverlayPanel,
    EmptyState,
    HjpDefaultModal
    // Row
  },
  props: {
    columns: {
      type: Array as PropType<IObjectOfColumns[]>,
      default: () => [],
    },
    endpoint: {
      type: String,
      default: () => '',
    },
    endpointSearch: {
      type: String,
      default: () => ''
    },
    // Character yg ditampilkan ketika data kosong
    emptyTable: {
      default: () => 'Data belum ada, silahkan tambah untuk mengisi data',
    },
    // Menampilkan kolom action
    showAction: {
      type: Boolean,
      default: () => true
    },
    headerTitle: {
      type: String,
      default: () => 'New Page'
    },
    buttonTitle: {
      type: String,
      default: () => 'Tambah'
    },
    // Character yg ditampilkan untuk placeholder search
    searchPlaceholder: {
      type: String,
      default: 'Cari Disini...'
    },
    isEdit: {
      type: Boolean,
      default: true
    },
    isChange: {
      type: Boolean,
      default: false
    },
    isDelete: {
      type: Boolean,
      default: true
    },
    editTitle: {
      type: String,
      default: ''
    },
    changeTitle: {
      type: String,
      default: ''
    },
    deleteTitle: {
      type: String,
      default: ''
    },
    // Untuk menampilkan form di halaman Baru
    isOpenNewPage: {
      type: Boolean,
      default: false
    },
    // Berisi atribute yg digunakan untuk mengisi content Confirm Dialog
    confirmDialogDelete: {
      type: Object as PropType<IConfirmDialogDelete>,
      default: () => ({
        imgContent: require('@/assets/img/modal-confirmation/delete-default.svg'),
      })
    },
    // Menampilkan colom aktif
    showColumnActivate: {
      type: Boolean,
      default: false
    },
    showBtnAdd: {
      type: Boolean,
      default: true
    },
    showSearch: {
      type: Boolean,
      default: true
    },
    endpointSuffix: {
      type: String,
      default: ''
    }
  },
  setup(props: any, { emit }: any) {
    const app = getCurrentInstance()
    const {
      $toast, $strInd
    } = app!.appContext.config.globalProperties
    const store = useStore()
    const dataSource = ref([])
    const { columns, emptyTable, endpoint, confirmDialogDelete, endpointSuffix, isChange } = toRefs(props)
    //   confirmDialogDelete.value.imgContent = require('@/assets/img/modal-confirmation/delete-default.svg')
    // const headerCustom = ref()
    // const dateColumns = ref(columns.map((x:)))
    const paginate = reactive({
      pageSize: 10,
      pageNumber: 1,
      totalRecords: 0,
    })
    const formIndex = ref(0)
    const dataRow = ref(null) as any
    const isLoading = ref(false)
    const searchCharacter = ref('')
    const refOverlayAction = ref()
    const showConfirmDialog = ref(false)
    const isChangeForm = ref(isChange.value)

    // const sidebarCanDelete = ref(store.state[moduleStore].rightbarForm.canDelete)
    // const isReloadTable = store.state[moduleStore].reloadTable
    const showModalForm = computed({
      get: () => store.state[moduleStore].modal.show,
      set: (value) => {
        store.dispatch('setShowModal', value)
      }
    })
    const showDialogForm = computed({
      get: () => store.state[moduleStore].dialog.show,
      set: (value) => {
        store.dispatch('setShowDialog', value)
      }
    })
    const sidebarCanDelete = computed({
      get: () => store.state[moduleStore].rightbarForm.canDelete,
      set: (value) => {
        store.dispatch('setToggleDatatableSidebarDeleteButton', value)
      }
    })
    const hideOverlay = () => {
      refOverlayAction.value.hide()
    }
    const hideModalForm = () => {
      showModalForm.value = false
    }
    const hideDialogForm = () => {
      showDialogForm.value = false
    }
    const setDataRowOnState = () => {
      // console.log('dataRow', dataRow.value)
      store.dispatch('storeRowDataTable', dataRow.value)
    }
    const getData = () => {
      isLoading.value = true
      const arrParams = []
      let paramsString = ''
      const columnsSearch = columns.value.filter((column: IObjectOfColumns) => column.searchable === true)
      if (searchCharacter.value && columnsSearch.length > 0) {
        columnsSearch.forEach((column: IObjectOfColumns) => {
          if (column.fieldSearch) {
            arrParams.push(`${column.fieldSearch}=${searchCharacter.value}`)
          }
        })
      }
      if (paginate.pageSize > 0) {
        arrParams.push(`pageSize=${paginate.pageSize}`)
      }
      if (paginate.pageNumber > 0) {
        arrParams.push(`pageNumber=${paginate.pageNumber}`)
      }
      paramsString = `?${arrParams.join('&')}`
      let fixEndpoint = endpoint.value
      if (endpointSuffix.value) {
        fixEndpoint = `${fixEndpoint}${endpointSuffix.value}`
      }
      apiUseCase.get(`${fixEndpoint}${paramsString}`).then((response) => {
        // console.log('response', response)
        const {
          result,
          pagesize,
          count
        } = response
        dataSource.value = result
        paginate.pageSize = pagesize
        paginate.totalRecords = count
        isLoading.value = false
      }).catch((err: any) => {
        isLoading.value = false
      })
    }
    const onSort = (val: any) => {
      console.log('onSort', val)
    }
    const onChangePage = (event: any) => {
      // console.log('onChangePage', event)
      paginate.pageNumber = event.page + 1
      // console.log('obj pagin', paginate)
      getData()
      const container = $('.wrap-hjp-data-table')
      $(window).scrollTop(container.offset().top)
      // $(window).animate({ scrollTop: 0 }, 3000)
    }
    const onSearch = (value: string) => {
      searchCharacter.value = value
      getData()
    }
    const showDropDown = (evt: any, item: any) => {
      dataRow.value = item
      console.log('dataRow', dataRow.value)
      isChangeForm.value = dataRow.value.Trayek ? true : false
      console.log('isChange', isChangeForm.value)
      refOverlayAction.value.toggle(evt)
    }
    const onDelete = (val: any) => {
      showConfirmDialog.value = true
      setDataRowOnState()
      hideOverlay()
    }
    const onEdit = (val: any) => {
      showModalForm.value = true
      setDataRowOnState()
      hideOverlay()
    }
    const onChange = (val: any) => {
      // formIndex.value = 1
      showDialogForm.value = true
      setDataRowOnState()
      hideOverlay()
    }
    const onClickBtnAdd = (val: any) => {
      store.dispatch('flushRowDataTable')
      showModalForm.value = true
    }
    const hideDialogConfirmation = () => {
      store.dispatch('flushRowDataTable')
      showConfirmDialog.value = false
    }
    const submitFromDialog = (val: any = '') => {
      store.dispatch('showLoading')
      const deletedId = val === '' ? dataRow.value.Id : val
      apiUseCase.delete(endpoint.value, deletedId).then((res: IResponse) => {
        if (!res.error) {
          $toast.add({
            severity: 'success',
            detail: 'Data berhasil dihapus',
            group: 'bc',
            closable: false,
            life: 3000
          })
          getData()
        } else {
          $toast.add({
            severity: 'error',
            detail: res.message,
            group: 'bc',
            closable: false,
            life: 3000
          })
        }
      }).catch((err: any) => {
        console.log('err delete', err)
      })
      hideDialogConfirmation()
      store.dispatch('hideLoading')
    }

    const changeIsActive = async (val: any) => {
      const data = {
        // Id: val.Id,
        IsActive: val.IsActive
      }
      const response = await apiUseCase.patch(endpoint.value, val.Id, data)
      if (!response.error) {
        $toast.add({
          severity: 'success',
          detail: 'Data berhasil diubah',
          group: 'bc',
          life: 3000
        })
      } else {
        $toast.add({
          severity: 'error',
          detail: response.message,
          group: 'bc',
          life: 3000
        })
        val.IsActive = !val.IsActive
      }
    }
    onMounted(() => {
      getData()
      sidebarCanDelete.value = true
    })

    watch(() => store.getters.reloadTable, (val) => {
      if (val) {
        getData()
      }
    })

    return {
      dataSource,
      isLoading,
      emptyTable,
      paginate,
      onSort,
      showDropDown,
      onDelete,
      onEdit,
      onClickBtnAdd,
      refOverlayAction,
      showModalForm,
      hideModalForm,
      showConfirmDialog,
      hideDialogConfirmation,
      submitFromDialog,
      onChangePage,
      onSearch,
      get,
      changeIsActive,
      confirmDialogDelete,
      onChange,
      formIndex,
      sidebarCanDelete,
      showDialogForm,
      hideDialogForm,
      isChangeForm
    }
  },
})
