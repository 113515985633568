/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import api from '@/configs/api_hjex'
import { IRemote } from './iRemote'
import remoteEnum from './enumActions'

// eslint-disable-next-line no-shadow

class Remote implements IRemote {
  method: remoteEnum

  endPoint: string

  header: any

  data: any

  constructor(method: remoteEnum, endPoint: string, data: any = null, header = {}) {
    this.method = method
    this.endPoint = endPoint
    this.header = header
    this.data = data

    const ut = localStorage.getItem('ut')
    // console.log('ut', ut)
    if (ut) {
      // this.header.authorization = `Bearer ${ut}`
      this.header.timeout = 5000
    }
  }

  get() {
    const remote = (this.header) ? api.get(this.endPoint, {
      headers: this.header
    }) : api.get(this.endPoint)
    return remote
  }

  post() {
    console.log('header', this.header)
    const remote = api.post(this.endPoint, this.data, {
      headers: this.header
    })
    return remote
  }

  put() {
    const remote = api.put(this.endPoint, this.data, {
      headers: this.header
    })
    return remote
  }

  patch() {
    const remote = api.patch(this.endPoint, this.data, {
      headers: this.header
    })
    return remote
  }

  delete() {
    const remote = api.delete(this.endPoint, {
      headers: this.header
    })
    return remote
  }

  run() {
    switch (this.method) {
      case remoteEnum.get:
        return this.get()
      case remoteEnum.post:
        return this.post()
      case remoteEnum.patch:
        return this.patch()
      case remoteEnum.put:
        return this.put()
      case remoteEnum.delete:
        return this.delete()
      default:
        return []
    }
  }
}

export { Remote, remoteEnum }
